/* eslint-disable @typescript-eslint/no-explicit-any */
// import baseApi from "@/services/base.service";
import baseApi from '@/services/base.service'
import Vue from 'vue'
import Router from 'vue-router'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)
Vue.use(Router)

const RouteGuard = (to: any, from: any, next: any) => {
  const loggedIn = localStorage.getItem('access_token')
  if (!loggedIn) return next('/login')
  baseApi.defaults.headers.common['Authorization'] = `Bearer ${loggedIn}`
  next()
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Layout,
    beforeEnter: RouteGuard,
    children: [
      {
        path: '/',
        name: 'Panel',
        component: () => import('../views/Panel.vue'),
        meta: { transition: 'zoom' },
        redirect: '/seller',
      },
      {
        path: '/qr-scan',
        name: 'QRScan',
        component: () => import('../views/QRScan.vue'),
        meta: { transition: 'fade-in-down' },
      },
      {
        path: '/qr-status',
        name: 'QRStatus',
        component: () => import('../views/QRStatus.vue'),
        meta: { transition: 'fade-in-down' },
      },
      {
        path: '/ticket-info',
        name: 'TicketInfo',
        component: () => import('../views/TicketInfo.vue'),
        meta: { transition: 'fade-in-down' },
      },
      {
        path: '/profile',
        name: 'Perfil de usuario',
        component: () => import('../views/Profile/Profile.vue'),
        meta: { transition: 'fade-in-down' },
      },
      {
        path: 'my-petitions',
        name: 'Mis peticiones',
        component: () =>
          import('../views/seller/Petitions/MyPetitionsList.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'comission-report',
        name: 'Reporte Comisiones',
        component: () =>
          import('../views/seller/SuperAdmin/ComissionReportSuperAdmin.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'pdf-report',
        name: 'Reporte de Recibos',
        component: () =>
          import('../views/seller/SuperAdmin/PdfReportSuperAdmin.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'settlement-report',
        name: 'Reporte de Liquidacion',
        component: () =>
          import('../views/seller/SuperAdmin/SetTlementReportSuperAdmin.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'stats',
        name: 'Estadísticas',
        component: () =>
          import('../views/seller/SuperAdmin/StatsSuperAdmin.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: '/generate-exlusive-ticket/:productUUID',
        name: 'Configurar Venta Exclusiva',
        component: () => import('../views/seller/GenerateExclusiveTicket.vue'),
        meta: { transition: 'fade-in-down' },
      },
    ],
  },
  {
    path: '/seller',
    component: Layout,
    beforeEnter: RouteGuard,
    children: [
      {
        path: 'menu',
        name: 'Eventos Menu',
        component: () => import('../views/seller/SellerMenu.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: '/',
        name: 'Eventos',
        component: () => import('../views/seller/Events.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'categories',
        name: 'Productos Categorías',
        component: () => import('../views/seller/Categories/CategoryList.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'vip',
        name: 'Productos VIP',
        component: () => import('../views/seller/Categories/OnlyVip.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'products',
        name: 'Mis productos',
        component: () => import('../views/seller/Products.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'all-products',
        name: 'Todos los productos',
        component: () => import('../views/seller/Products/AllProducts.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'products/vip',
        name: 'Mis productos Vip',
        component: () => import('../views/seller/ProductsVip.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'product-detail/:productId',
        name: 'Detalle del producto',
        component: () => import('../views/seller/ProductDetail.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'vip/product-detail/:productId',
        name: 'Detalle del producto vip',
        component: () => import('../views/seller/ProductVipDetail.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'generate-link/:productId',
        name: 'Generar Link',
        component: () => import('../views/seller/GenerateLink.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'generate-qr/:productId',
        name: 'Generar qr',
        component: () => import('../views/seller/GenerateQr.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'share-link/:productId',
        name: 'Compartir Link',
        component: () => import('../views/seller/ShareLink.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'links',
        name: 'Links',
        component: () => import('../views/seller/Links.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'all-links',
        name: 'all-links',
        component: () => import('../views/seller/Links.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'links/vip',
        name: 'Links',
        component: () => import('../views/seller/LinksVip.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'links/:linkId',
        name: 'Link',
        component: () => import('../views/seller/Links/LinkView.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'links-admin/:linkId',
        name: 'Link-how-admin',
        component: () => import('../views/seller/Links/LinkView.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'report',
        name: 'Reporte',
        component: () => import('../views/seller/Report.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'event/assistants',
        name: 'Asistentes',
        component: () => import('../views/seller/Assistants.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'assistant-profile/:id',
        name: 'Perfil del asistente',
        component: () => import('../views/seller/AssistantProfile.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'assistant-profile/:id/event/:eventId',
        name: 'Evento del asistente',
        component: () => import('../views/seller/AssistantProfileEvent.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'petitions',
        name: 'Peticiones',
        component: () => import('../views/seller/Petitions/PetitionsList.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration',
        name: 'Administración',
        component: () =>
          import('../views/seller/Administration/AdministrationMenu.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration/seller-list',
        name: 'Vendedores',
        component: () =>
          import('../views/seller/Administration/ListSellers.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'administration/seller-product/:sellerId',
        name: 'Productos',
        component: () =>
          import('../views/seller/Administration/productsOfSeller.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration/seller-create',
        name: 'Crear vendedor',
        component: () =>
          import('../views/seller/Administration/SellerForm.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration/sellers-by-location/:id',
        name: 'Listar vendedores por local',
        component: () =>
          import('../views/seller/Administration/ListSellersByLocation.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration/seller-locations',
        name: 'Ver locales',
        component: () =>
          import('../views/seller/Administration/ListLocations.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration/seller-create-locations',
        name: 'Crear locales',
        component: () =>
          import('../views/seller/Administration/SellerLocationForm.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration/seller-edit-locations/:id',
        name: 'Crear locales',
        component: () =>
          import('../views/seller/Administration/SellerLocationForm.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'administration/seller-edit/:id',
        name: 'Editar vendedor',
        component: () =>
          import('../views/seller/Administration/SellerForm.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'administration/category-list',
        name: 'Vendedores',
        component: () =>
          import('../views/seller/Administration/ListCategories.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'administration/category-edit/:id',
        name: 'Editar Categoria',
        component: () =>
          import('../views/seller/Administration/CategoryForm.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'administration/category-create',
        name: 'Crear Categoria',
        component: () =>
          import('../views/seller/Administration/CategoryForm.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: '/seller/administration/recepcionist-list',
        name: 'crear_recepcionista_admin',
        component: () =>
          import('../views/seller/Administration/ListRecepcionistsAdmin.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: '/seller/administration/recepcionist-edit/:id',
        name: 'editar_recepcionista_admin',
        component: () =>
          import('../views/seller/Administration/EditRecepcionistAdmin.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: '/seller/administration/recepcionist-create',
        name: 'editar_recepcionista_admin',
        component: () =>
          import('../views/seller/Administration/CreateRecepcionistAdmin.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'administration/all-assistants',
        name: 'Asistentes Globales',
        component: () => import('@/views/seller/Administration/AllAssistants.vue')
      },

      {
        path: 'share-user',
        name: 'Compartir usuario',
        component: () => import('../views/seller/ShareUser.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'pricing/:id',
        name: 'Precios',
        component: () =>
          import('../views/seller/SuperAdmin/PricingSuperAdmin.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'share-microsite',
        name: 'Compartir Micrositio',
        component: () => import('../views/seller/ShareMicrosite.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'recepcionists',
        name: 'Recepcionistas',
        component: () => import('../views/Recepcionists/ListRecepcionists.vue'),
        meta: { transition: 'zoom' },
      },

      {
        path: 'recepcionist/edit/:id',
        name: 'Editar recepcionista',
        component: () =>
          import('../views/seller/Administration/EditRecepcionistAdmin.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'refund-ecommerce-ticket',
        name: 'Reembolsar Ticket',
        component: () => import('../views/seller/RefundTicket.vue'),
        meta: { transition: 'zoom' },
      },
      {
        path: 'my-qr',
        name: 'Mi QR',
        component: () => import('../views/Profile/MyQR.vue'),
        meta: { transition: 'fade-in-down' },
      },
      {
        path: 'refund-ecommerce-ticket',
        name: 'Reembolsar Ticket',
        component: () => import('../views/seller/RefundTicket.vue'),
        meta: { transition: 'zoom' },
      },
    ],
  },
  {
    path: '/login',
    name: 'Home',
    component: () => import('../views/auth/Login.vue'),
    meta: { transition: 'fade-in-down' },
  },
  {
    path: '/ticket',
    name: 'PublicRole',
    component: () => import('../views/PublicRole.vue'),
    meta: { transition: 'fade-in-down' },
  },
  {
    path: '/ticket-verification/:id',
    name: 'Ticket',
    component: () => import('../components/PublicRole/PublicRoleStatus.vue'),
    meta: { transition: 'fade-in-down' },
  },
  {
    path: '/generate-qr',
    name: 'Generar QR',
    component: () => import('../components/PublicRole/GenerateQR.vue'),
    meta: { transition: 'fade-in-down' },
  },
  {
    path: '/ticket-qr/:data',
    name: 'Generar Ticket QR',
    component: () => import('../views/seller/TicketQR.vue'),
    meta: { transition: 'fade-in-down' },
  },
]

const DEFAULT_TITLE = 'App'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE + ' ' + to.name
  })
})

export default router
